import * as Yup from 'yup';

import {
  BRAZILIAN_STATES,
  ROLE_STATES,
  ADOPTER_STATES,
  TYPE_SCHOOL,
} from '@constants';

const registerShape = {
  name: Yup.string().required('valid.name.req'),
  institution: Yup.string().required('valid.institution.req'),
  email: Yup.string().required('valid.email.req').email('valid.email.invalid'),
  phone: Yup.string(),
  institution_other: Yup.string(),
  aboout_us_other: Yup.string(),
  opee_adopter_other: Yup.string(),
  city: Yup.string().required('valid.city.req'),
  district: Yup.string()
    .required('valid.state.req')
    .test('district', 'valid.state.invalid', (district) => {
      if (!district) {
        return true;
      }
      const schema = Yup.string().oneOf(Object.keys(BRAZILIAN_STATES));
      return schema.isValidSync(district);
    }),
  role: Yup.string()
    .required('valid.role.req')
    .test('role', 'valid.role.invalid', (role) => {
      if (!role) {
        return true;
      }
      const schema = Yup.string().oneOf(Object.keys(ROLE_STATES));
      return schema.isValidSync(role);
    }),
  opee_adopter: Yup.string()
    .required('valid.opee_adopter.req')
    .test('opee_adopter', 'valid.opee_adopter.invalid', (opee_adopter) => {
      if (!opee_adopter) {
        return true;
      }
      const schema = Yup.string().oneOf(Object.keys(ADOPTER_STATES));
      return schema.isValidSync(opee_adopter);
    }),
  about_us: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
    )
    .min(1, 'valid.about_us.req'),
  ftd_news: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
    )
    .min(1, 'valid.ftd_news.req'),
  type_school: Yup.string()
    .required('valid.type_school.req')
    .test('adopter', 'valid.type_school.invalid', (type_school) => {
      if (!type_school) {
        return true;
      }
      const schema = Yup.string().oneOf(Object.keys(TYPE_SCHOOL));
      return schema.isValidSync(type_school);
    }),
};

export const registerSchemas = {
  1: Yup.object().shape({
    ...registerShape,
    password: Yup.string().min(6, 'valid.password.req'),
    password_confirmation: Yup.string()
      .min(6, 'valid.password.retry')
      .oneOf([Yup.ref('password'), null], 'valid.password.invalid'),
  }),
  2: Yup.object().shape(registerShape),
};
