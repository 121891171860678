/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable radar/cognitive-complexity */
import {
  ChangeEventHandler,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';

import { Trans } from 'react-i18next';
import { FieldError, useFormContext } from 'react-hook-form';
import { FiAlertCircle } from 'react-icons/fi';
import { useTheme } from 'styled-components';

import Button from '@components/Button';
import { MaskTypes } from '@utils/mask-value/constants';
import { Suggestion } from '@common/types/api';
import { fetchSuggestions } from '@hooks/useSuggestionsQuery';

import * as S from './styles';

type Params = {
  name: string;
  value: string;
};

export type AutoCompleteProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  mask?: MaskTypes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setIsInputOtherVisible: any;
  params: Params[];
  url?: string;
  icon:
    | import('react').ElementType<import('react-icons').IconBaseProps>
    | JSX.Element;
  buttonIcon?:
    | import('react').ElementType<import('react-icons').IconBaseProps>
    | JSX.Element;
};

const AutoComplete = (props: AutoCompleteProps) => {
  const { colors } = useTheme();
  const { register, formState, watch, setValue } = useFormContext();

  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [isFilled, setIsFilled] = useState(false);

  const {
    name,
    id,
    icon: Icon,
    type = 'text',
    required,
    disabled,
    className,
    buttonIcon: ButtonIcon,
    setIsInputOtherVisible,
    params,
    // eslint-disable-next-line radar/no-duplicate-string
    url = '/utils/search',
    ...attrs
  } = props;
  delete attrs.value;

  const observedInput = watch(name, '');
  const inputError = formState.errors[name] as FieldError;

  const { onChange, ...registerRef } = register(name, {
    required,
  });

  const cleanSearch = (_e: React.FocusEvent<HTMLInputElement>) => {
    if (
      suggestions.length === 1 &&
      _e.target.value &&
      _e.target.value !== 'Outra'
    ) {
      setValue(name, '');
      document.body.addEventListener('click', (evt) => {
        if (document.activeElement !== evt.target) {
          setSuggestions([]);
        }
      });
    }
  };
  const setSuggestion = (value: string) => {
    if (value === 'Outra') setIsInputOtherVisible(true);

    setSuggestions([]);
    setValue(name, value);
  };

  const getSuggestions: ChangeEventHandler<HTMLInputElement> = async (e) => {
    setIsInputOtherVisible(false);

    if (!e.target.value) {
      setSuggestions([]);
    } else {
      const currentSuggestions = await fetchSuggestions(
        e.target.value,
        url,
        params,
      );

      if (currentSuggestions.data && currentSuggestions.data.length) {
        setSuggestions(currentSuggestions.data);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (url === 'utils/search') {
          setSuggestions([
            {
              id: 0,
              name: 'Outra',
              value: (
                <S.HighlightedText>
                  Não encontrado - clique aqui e digite abaixo a sua escola
                </S.HighlightedText>
              ),
            },
          ]);
        } else {
          setSuggestions([]);
        }
      }
    }

    onChange(e);
  };

  useEffect(() => {
    setIsFilled(!!observedInput);
  }, [observedInput]);

  return (
    <>
      <S.Container
        htmlFor={id || name}
        $error={!!inputError}
        $filled={isFilled}
        $disabled={disabled}
        className={className}
        {...attrs.tabIndex}
      >
        {Icon && typeof Icon === 'function' ? <Icon size="1.25rem" /> : Icon}

        <input
          id={id || name}
          type={type}
          disabled={disabled}
          readOnly={disabled}
          {...attrs}
          {...registerRef}
          onChange={getSuggestions}
          onBlur={cleanSearch}
        />

        {inputError && (
          <S.ErrorWrapper>
            <FiAlertCircle color={colors.error[100]} size="0.875rem" />
            <Trans>{inputError.message}</Trans>
          </S.ErrorWrapper>
        )}

        {ButtonIcon && (
          <Button
            type="submit"
            size="even"
            icon={ButtonIcon}
            variant="transparent"
            disabled={disabled}
          />
        )}

        <S.Suggestions $active={suggestions.length > 0}>
          {suggestions.map((suggestion) => (
            <li key={suggestion.id}>
              <button
                type="button"
                onClick={() => setSuggestion(suggestion.name)}
              >
                <span>{suggestion.value || suggestion.name}</span>
              </button>
            </li>
          ))}
        </S.Suggestions>
      </S.Container>
    </>
  );
};

AutoComplete.defaultProps = {
  type: 'text',
  value: '',
  placeholder: '',
  required: false,
  onChange: () => null,
  params: [],
  url: '/utils/search',
} as Partial<AutoCompleteProps>;

export default AutoComplete;
