import { createGlobalStyle } from 'styled-components';

import { THEME_CSS_VARIABLES } from './css-variables';
import 'react-image-crop/dist/ReactCrop.css';

export const GlobalStyles = createGlobalStyle`
  ${THEME_CSS_VARIABLES}

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    list-style: none;
    box-sizing: border-box;
  }

  html {
    @media  (max-width: 1366px) {
      font-size: 87.5%; // 14px
    }

    /* @media  (max-width: 768px) {
      font-size: 87.5%%; // 16px
    } */
  }


  html, body, #root {
    min-height: 100%;
  }

  body {
    background: ${({ theme }) => theme.colors.background[100]};
    color: ${({ theme }) => theme.colors.foreground[100]};
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
  }

  body, #root {
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  #root {
    height: 100%;
  }

  body, input, textarea, button {
    font: 0.895rem/1.48 "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 400;
  }

  h1, h2, h3, strong {
    font-weight: 600;
  }

  h4, h5, h6 {
    font-weight: 500;
  }

  a {
    text-decoration: none;
    color: inherit;
    background: none;
    font-weight: 600;
    cursor: pointer;
    border: 0;
    transition: 180ms ease-in-out;
  }

  button {
    border: 0;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    :not(:disabled) {
      cursor: pointer;
    }
  }

  a, button {
    -webkit-tap-highlight-color: transparent;

    :not(:disabled):hover {
      filter: brightness(1.025);
    }
   }

  ul {
    list-style: none;
    text-align: left;
    padding: 0;
  }

  svg {
    flex-shrink: 0;
  }

  .tippy-content {
      padding: 0;
    }
`;
