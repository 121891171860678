import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useHistory } from 'react-router-dom';
import { FiCheck, FiLock, FiMail, FiPhone, FiUser } from 'react-icons/fi';
import { MdSchool } from 'react-icons/md';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';
import { AnimatePresence } from 'framer-motion';
import TagManager from 'react-gtm-module';

import {
  Button,
  Input,
  MetaTags,
  Modal,
  // Select,
  AutoComplete,
  SelectCustom,
  TawkTo,
  WhatsAppButton,
} from '@components';
import { useAuth, useEventfy, useEventTermsQuery, useToggle } from '@hooks';
import { IntegrationLocation, RegisterUserRequest } from '@common/types/api';
import { HistoryState } from '@services/history';
// import DropdownLang from '@pages/_layouts/private/Navigation/DropdownLang';

import { registerSchemas } from './schemas';
import * as S from '../styles';
import {
  SELECT_OPTIONS,
  ADOPTER_OPTIONS,
  ROLE_OPTIONS,
  FOUNDUS_OPTIONS,
  TYPE_SCHOOL_OPTIONS,
  NEWS_OPTIONS,
} from './options';
import TermsModal from './TermsModal';

type RegisterFields = RegisterUserRequest & {
  password_confirmation: string;
};

type SignUpError = AxiosError<{
  errors: Record<keyof RegisterUserRequest, string[]>;
}>;

const SignUp = () => {
  const { t } = useTranslation();
  const event = useEventfy();
  const { signUp } = useAuth();
  const history = useHistory<HistoryState>();
  const [isInputOtherVisible, setIsInputOtherVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isInputOtherVisibleCity, setIsInputOtherVisibleCity] = useState(false);
  const referral = new URLSearchParams(history.location.search).get('referral');

  const termsQuery = useEventTermsQuery();

  const formMethods = useForm<RegisterUserRequest>({
    resolver: yupResolver(registerSchemas[event.access_type]),
  });
  const { setError, reset: resetForm } = formMethods;

  const [isLoading, setIsLoading] = useState(false);

  const [hasAcceptedTerms, toggleHasAcceptedTerms] = useToggle(false);
  const [shouldDisplayTerms, toggleShouldDisplayTerms] = useToggle(false);

  const adopterValue = useWatch({
    control: formMethods.control,
    name: 'opee_adopter',
  });
  const roleValue = useWatch({
    control: formMethods.control,
    name: 'role',
  });

  const districtValue = useWatch({
    control: formMethods.control,
    name: 'district',
  });

  const handleSignUp = async (fields: RegisterFields) => {
    const { password_confirmation: _, ...credentials } = fields;
    try {
      setIsLoading(true);
      if (referral) {
        credentials.referral_code = referral;
      }

      if (isInputOtherVisible && !credentials.institution_other) {
        toast.error('Informe o nome da Escola!');
        return;
      }

      await signUp(credentials);

      if (event.tag_google_gtm) {
        TagManager.dataLayer({ dataLayer: { event: 'eventfy_registered' } });
      }
      const message = t('sign_up.success') || 'Conta criada som sucesso!';
      toast.success(message);

      if (event.session_login) {
        history.push(history.location.state?.from || '/lobby');
      } else {
        setIsLoading(false);
        toggleHasAcceptedTerms();
        resetForm();
        history.push('/');
      }
    } catch (err) {
      const fallbackMessage = t('sign_up.fail');

      if (err?.isAxiosError) {
        const errorResponse = (err as SignUpError).response;

        if (errorResponse) {
          Object.entries(errorResponse.data.errors).forEach(
            ([key, [message]]) => {
              const field = key as keyof RegisterUserRequest;

              setError(field, {
                type: 'required',
                message,
                shouldFocus: field === 'email',
              });
            },
          );
        }
        const message = t('sign_up.fail');
        toast.error(message);
      } else {
        toast.error(fallbackMessage);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (termsQuery && !termsQuery.data) {
      toggleHasAcceptedTerms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termsQuery, toggleHasAcceptedTerms]);

  return (
    <>
      <S.Container>
        <MetaTags title={t('sign_up.title')} />

        <S.Header>
          <h1>
            <Link to="/sign-in">
              <img src={event.logo} alt={event.name} />
            </Link>
          </h1>

          <Button
            title={t('sign_in.title') || 'Login'}
            variant="outline"
            as={Link}
            to="/sign-in"
          />
        </S.Header>
        {/* <S.Lang>
          <DropdownLang />
        </S.Lang> */}
        <FormProvider {...formMethods}>
          <S.Form onSubmit={formMethods.handleSubmit(handleSignUp)}>
            <h2>{t('sign_up.title')}</h2>

            <p>{t('sign_up.message')}</p>

            <S.FlexWrapper $break>
              <Input
                name="name"
                placeholder={t('input.name')}
                icon={FiUser}
                autoFocus
                required
              />

              <Input
                name="phone"
                type="tel"
                mask="phone"
                placeholder={t('input.phone')}
                autoComplete="phone"
                inputMode="tel"
                icon={FiPhone}
              />
            </S.FlexWrapper>
            <Input
              name="email"
              type="email"
              placeholder="E-mail"
              autoComplete="email"
              inputMode="email"
              icon={FiMail}
              required
            />
            {+event.access_type === 1 && (
              <>
                <Input
                  id="new-password"
                  name="password"
                  type="password"
                  placeholder={t('input.password')}
                  autoComplete="new-password"
                  icon={FiLock}
                  required
                />

                <Input
                  name="password_confirmation"
                  type="password"
                  placeholder={t('input.passwordRetry')}
                  autoComplete="off"
                  icon={FiLock}
                  required
                />
              </>
            )}
            <S.FlexWrapper $break>
              <SelectCustom
                name="district"
                placeholder={t('input.state')}
                options={SELECT_OPTIONS}
              />
              <AutoComplete
                id="city"
                name="city"
                placeholder="Cidade"
                setIsInputOtherVisible={setIsInputOtherVisibleCity}
                params={[{ name: 'uf', value: districtValue }]}
                url="utils/cities"
                required
              />
              {/* <Input name="city" placeholder={t('input.city')} required /> */}
            </S.FlexWrapper>

            <S.FlexWrapper $break>
              <SelectCustom
                name="opee_adopter"
                placeholder={t('input.opee_adopter')}
                options={ADOPTER_OPTIONS}
                required
              />
              <SelectCustom
                name="type_school"
                placeholder={t('input.type_school')}
                required
                options={TYPE_SCHOOL_OPTIONS}
              />
            </S.FlexWrapper>
            {adopterValue === 'Não' && (
              <Input
                name="opee_adopter_other"
                placeholder="Adota material Socioemocional, Qual?"
                required
              />
            )}

            <AutoComplete
              id="institution"
              name="institution"
              placeholder="Informe o nome da Escola"
              setIsInputOtherVisible={setIsInputOtherVisible}
              params={[{ name: 'uf', value: districtValue }]}
              url="utils/search"
              required
            />
            {/* <AutoComplete
              id="institution"
              name="institution"
              placeholder="Informe o nome da Escola"
              setIsInputOtherVisible={setIsInputOtherVisible}
              icon={MdSchool}
              required
            /> */}
            {isInputOtherVisible && (
              <Input
                id="institution_other"
                name="institution_other"
                placeholder="Informe o nome da Escola"
                icon={MdSchool}
                required
              />
            )}
            <S.FlexWrapper $break>
              <SelectCustom
                name="role"
                placeholder={t('input.role')}
                options={ROLE_OPTIONS}
                required
              />
              {roleValue === 'Outro' && (
                <Input
                  name="role_other"
                  placeholder={t('input.role_other')}
                  required
                />
              )}
            </S.FlexWrapper>
            <S.FlexWrapper>
              <SelectCustom
                name="about_us"
                placeholder={t('input.about_us')}
                options={FOUNDUS_OPTIONS}
                required
                isMulti
              />
            </S.FlexWrapper>

            <SelectCustom
              name="ftd_news"
              placeholder={t('input.ftd_news')}
              options={NEWS_OPTIONS}
              required
              isMulti
            />
            {termsQuery?.data && (
              <S.TermsWrapper>
                <p>
                  {t('sign_up.termsText', { event: event.name })}
                  <button type="button" onClick={toggleShouldDisplayTerms}>
                    {t('sign_up.terms')}
                  </button>
                  .
                </p>

                <S.TermsAgreement htmlFor="terms">
                  <input
                    id="terms"
                    name="terms"
                    type="checkbox"
                    checked={hasAcceptedTerms}
                    onChange={toggleHasAcceptedTerms}
                  />
                  <span>
                    <FiCheck />
                  </span>
                  {t('sign_up.accepted')}
                </S.TermsAgreement>
              </S.TermsWrapper>
            )}

            <Button
              pill
              fullWidth
              loading={isLoading}
              disabled={!hasAcceptedTerms}
              title={t('button.register') || 'Sign up'}
              type="submit"
            />
          </S.Form>
        </FormProvider>

        <TawkTo location={IntegrationLocation.REGISTER} />
        <WhatsAppButton location={IntegrationLocation.REGISTER} />
      </S.Container>

      <AnimatePresence>
        {shouldDisplayTerms && (
          <Modal handleClose={toggleShouldDisplayTerms}>
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            <TermsModal data={termsQuery.data!} />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default SignUp;
