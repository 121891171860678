import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { SuggestionsResponse } from '@common/types/api';
import { api } from '@services';
import { PromiseWithCancel } from '@common/types';
import { cancelToken } from '@services/api';

type Params = {
  name: string;
  value: string;
};

export const fetchSuggestions = (
  search: string,
  url: string,
  params: Params[],
) => {
  const source = cancelToken();
  let endpoint = `${url}?search=${search}`;

  if (params) {
    params.forEach((param) => {
      endpoint += `&${param.name}=${param.value}`;
    });
  }

  const promise = api
    .get<SuggestionsResponse>(endpoint, {
      cancelToken: source.token,
    })
    .then((res) => res.data) as PromiseWithCancel<SuggestionsResponse>;

  promise.cancel = () => source.cancel('useSuggestionsQuery Cancelled');

  return promise;
};

export const useSuggestionsQuery = (
  search: string,
  url: string,
  params: Params[],
) =>
  useQuery<SuggestionsResponse, AxiosError>(['suggestions', search], () =>
    fetchSuggestions(search, url, params),
  );
