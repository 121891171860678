export const BRAZILIAN_STATES = <const>{
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goías',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraíma',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
};
export const ROLE_STATES = <const>{
  'Responsável de criança e/ou adolescente':
    'Responsável de criança e/ou adolescente',
  'Gestor(a) / Mantenedor(a)': 'Gestor(a) / Mantenedor(a)',
  'Coordenador(a)': 'Coordenador(a)',
  'Professor(a)': 'Professor(a)',
  Assistente: 'Assistente',
  Administrativo: 'Administrativo',
  'Marketing / Comunicação': 'Marketing / Comunicação',
  Outro: 'Outro',
};

export const FTD_NEWS_STATES = <const>{
  'Conteúdos relacionados ao evento': 'Conteúdos relacionados ao evento',
  'Divulgação de produtos e serviços': 'Divulgação de produtos e serviços',
  'Não autorizo': 'Não autorizo',
};

export const FOUNDUS_STATES = <const>{
  'E-mail marketing': 'E-mail marketing',
  'Redes sociais OPEE': 'Redes sociais OPEE',
  'Redes sociais FTD': 'Redes sociais FTD',
  'Leo Fraiman': 'Leo Fraiman',
  'Assessores OPEE': 'Assessores OPEE',
  Indicação: 'Indicação',
  'Contato FTD': 'Contato FTD',
  Teenager: 'Teenager',
  'Escola que trabalho/ Escola do(a) meu(minha) filho(a)':
    'Escola que trabalho/ Escola do(a) meu(minha) filho(a)',
  Outro: 'Outro',
};

export const TYPE_SCHOOL = <const>{
  Pública: 'Pública',
  Particular: 'Particular',
};

export const ADOPTER_STATES = <const>{
  Sim: 'Sim',
  Não: 'Não',
};
