import { DispatchWithoutAction } from 'react';

import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { Link } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { FiMenu } from 'react-icons/fi';

import { useAuth, useEventfy, useToggle } from '@hooks';
import { Avatar, Button, Modal } from '@components';
import { selectUser } from '@hooks/useAuth/selectors';
import { IndicationsIllustration } from '@assets';

import Sidebar from './Sidebar';
import * as S from './styles';
import DropdownMenu from './DropdownMenu';
// import DropdownLang from './DropdownLang';
import UserProfileModal from './UserProfileModal';
import IndicationsModal from './IndicationsModal';
import MobileMenu from './MobileMenu';
import UserMobileMenu from './UserMobileMenu';

type NavigationProps = {
  handleSurvey: DispatchWithoutAction;
  handleCertificate: DispatchWithoutAction;
};

export const Navigation = ({
  handleSurvey,
  handleCertificate,
}: NavigationProps) => {
  const { name, logo, indication } = useEventfy();
  const user = useAuth(selectUser);

  const shouldShowDesktopSidebar = useBreakpoint(up('lg'));

  const [isMobileMenuVisible, toggleIsMobileMenuVisible] = useToggle(false);
  const [isUserMobileMenuVisible, toggleIsUserMobileMenuVisible] =
    useToggle(false);
  const [isProfileModalVisible, toggleIsProfileModalVisible] = useToggle(false);
  const [isIndicationsModalVisible, toggleIsIndicationsModalVisible] =
    useToggle(false);

  return (
    <S.Container>
      <S.Header>
        {!shouldShowDesktopSidebar && (
          <Button
            size="even"
            variant="transparent"
            icon={<FiMenu size="1.625rem" />}
            onClick={toggleIsMobileMenuVisible}
          />
        )}

        <Link to="/lobby">
          <img src={logo} alt={name} />
        </Link>

        <div>
          {/* <Button variant="transparent" size="even" icon={FiBell} /> */}
          {/* <Button variant="transparent" size="even" icon={FiMessageSquare} /> */}
          {!!indication && (
            <S.IndicationsButton onClick={toggleIsIndicationsModalVisible}>
              <IndicationsIllustration />
            </S.IndicationsButton>
          )}

          {shouldShowDesktopSidebar ? (
            <>
              {/* <DropdownLang /> */}
              <DropdownMenu toggleProfileModal={toggleIsProfileModalVisible} />
            </>
          ) : (
            <S.UserMobileMenuButton
              type="button"
              onClick={toggleIsUserMobileMenuVisible}
            >
              <Avatar src={user.avatar} alt={user.name} />
            </S.UserMobileMenuButton>
          )}
        </div>
      </S.Header>

      {shouldShowDesktopSidebar && (
        <Sidebar
          handleSurvey={handleSurvey}
          handleCertificate={handleCertificate}
        />
      )}

      {!shouldShowDesktopSidebar && (
        <>
          <AnimatePresence>
            {isMobileMenuVisible && (
              <Modal handleClose={toggleIsMobileMenuVisible}>
                <MobileMenu
                  handleSurvey={handleSurvey}
                  handleCertificate={handleCertificate}
                  handleClose={toggleIsMobileMenuVisible}
                />
              </Modal>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {isUserMobileMenuVisible && (
              <Modal handleClose={toggleIsUserMobileMenuVisible}>
                <UserMobileMenu
                  handleProfileModal={toggleIsProfileModalVisible}
                  handleClose={toggleIsUserMobileMenuVisible}
                />
              </Modal>
            )}
          </AnimatePresence>
        </>
      )}

      <AnimatePresence>
        {isProfileModalVisible && (
          <Modal handleClose={toggleIsProfileModalVisible}>
            <UserProfileModal handleClose={toggleIsProfileModalVisible} />
          </Modal>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isIndicationsModalVisible && (
          <Modal handleClose={toggleIsIndicationsModalVisible}>
            <IndicationsModal handleClose={toggleIsIndicationsModalVisible} />
          </Modal>
        )}
      </AnimatePresence>
    </S.Container>
  );
};

export default Navigation;
