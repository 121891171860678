import {
  BRAZILIAN_STATES,
  FOUNDUS_STATES,
  ADOPTER_STATES,
  TYPE_SCHOOL,
  ROLE_STATES,
  FTD_NEWS_STATES,
} from '@constants';

export const SELECT_OPTIONS = Object.entries(BRAZILIAN_STATES).map(
  ([key, _value]) => ({
    value: key,
    label: key,
  }),
);
export const FOUNDUS_OPTIONS = Object.entries(FOUNDUS_STATES).map(
  ([key, _value]) => ({
    value: key,
    label: key,
  }),
);

export const ADOPTER_OPTIONS = Object.entries(ADOPTER_STATES).map(
  ([key, _value]) => ({
    value: key,
    label: key,
  }),
);

export const TYPE_SCHOOL_OPTIONS = Object.entries(TYPE_SCHOOL).map(
  ([key, _value]) => ({
    value: key,
    label: key,
  }),
);
export const ROLE_OPTIONS = Object.entries(ROLE_STATES).map(
  ([key, _value]) => ({
    value: key,
    label: key,
  }),
);
export const NEWS_OPTIONS = Object.entries(FTD_NEWS_STATES).map(
  ([key, _value]) => ({
    value: key,
    label: key,
  }),
);
