import { Controller } from 'react-hook-form';
import { FieldError, useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { FiAlertCircle } from 'react-icons/fi';
import { useTheme } from 'styled-components';

import { Container, ReactSelect, ErrorWrapper } from './styles';

type SelectOption = { value: string; label: string };

export type SelectProps = {
  name: string;
  placeholder: string;
  isMulti?: boolean;
  required?: boolean;
  options: Array<SelectOption>;
  // onChange?: (...event: any[]) => void;
};

const SelectCustom = ({
  name,
  placeholder,
  options,
  required = false,
  isMulti = false,
}: SelectProps) => {
  const { colors } = useTheme();
  const { control, formState } = useFormContext();
  const inputError = formState.errors[name] as FieldError;

  return (
    <Container>
      <Controller
        name={name}
        defaultValue={isMulti ? [] : ''}
        control={control}
        rules={{ required }}
        render={({ field }) => (
          <ReactSelect
            {...field}
            $error={!!inputError}
            // menuPortalTarget={menuPortalTarget}
            menuPortalTarget={document.querySelector('#root')}
            // styles={reactStyles}
            options={options}
            placeholder={placeholder}
            isMulti={isMulti}
            maxMenuHeight={250}
            value={options.find((option) => option.value === field.value)}
            onChange={(e: SelectOption) =>
              field.onChange(isMulti ? e : e.value)
            }
            // onChange={handleOnChange}
          />
        )}
      />
      {inputError && (
        <ErrorWrapper>
          <FiAlertCircle color={colors.error[100]} size="0.875rem" />
          <Trans>{inputError.message}</Trans>
        </ErrorWrapper>
      )}
    </Container>
  );
};

export default SelectCustom;
